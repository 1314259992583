import { IOfferPosition } from '@/api/models/offer-position-interface';

export class OfferPosition implements IOfferPosition {
  constructor(
    public offer_id?: number,
    public position?: number,
    public organization_id?: number,
    public image_url?: string,
    public header?: string,
    public used?: number,
    public total?: number
  ) {
  }
}
