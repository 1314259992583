import { createApp } from 'vue';

import 'animate.css';
import '@fortawesome/fontawesome-free/js/all.min';
import CountryFlag from 'vue-country-flag-next';

import Quasar from 'quasar/src/vue-plugin.js';;

import App from '@/App.vue';

import validators from '@/helpers/validators';
import router from './router/index.js';

import quasarUserOptions from './quasar-user-options';

import store from './store/index.js';

import filters from './helpers/filters';

import './helpers/axios';

export const app = createApp(App);

window.app = app;
app.config.globalProperties.$filters = filters;
app.config.globalProperties.$validators = validators;

app.use(store)
  .use(router)
  .use(Quasar, quasarUserOptions)
  .use(CountryFlag)
  .mount('#app');

export default app;
