export function phoneToNumber(phone) {
  try {
    if (!phone) {
      return '';
    }

    return phone.toString().replace(/\D/g, '');
  } catch (e) {
    console.warn(e);
    return '';
  }
}

export function numberToPhone(phone) {
  let d = phone.toString();

  if (d.length < 11) d = d.padEnd(11, ' ');

  return `+${d[0]} (${d[1]}${d[2]}${d[3]}) ${d[4]}${d[5]}${d[6]} ${d[7]}${d[8]} ${d[9]}${d[10]}`;
}
