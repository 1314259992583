import { Banner } from '@/entities/banner-entity';

export default {
  state: {
    data: [],
    positions: [],
    editItem: new Banner(),
  },
  mutations: {
    updateBanners(state: { data: any; }, banners: Banner[]) {
      state.data = banners;
    },
    updateBannersDropped(state: { data: Banner[]; positions: any; }, banners: any[]) {
      state.data = banners.map((el: Banner, index) => (new Banner(el.link, el.image, el.id, ++index)));// { ...el, position: ++index }
      state.positions = state.data.map((el) => ({
        id: el.id,
        position: el.position,
      }));
    },
    editBannerItemUpdate(state: { editItem: Banner; }, item: Banner) {
      if (item) {
        state.editItem = new Banner(item.link, item.image);
      } else {
        state.editItem = new Banner();
      }
    },
    deleteBannerItem(state: { data: Banner[]; }, itemId: number) {
      state.data = state.data.filter((el) => el.id !== itemId)
        .map((el, index) => (new Banner(el.link, el.image, el.id, ++index)));// { ...el, position: ++index }
    },
    clearBannerPositions(state: { positions: Banner[]; }) {
      state.positions = [];
    },
  },
  actions: {
    updateBanners(store: any, banners: Banner[]) {
      store.commit('updateBanners', banners);
    },
    updateBannersDropped(store: any, banners: Banner[]) {
      store.commit('updateBannersDropped', banners);
    },
    editBannerItemUpdate(store: any, item: Banner) {
      store.commit('editBannerItemUpdate', item);
    },
    deleteBannerItem(store: any, itemId: number) {
      store.commit('deleteBannerItem', itemId);
    },
    clearBannerPositions(store: any) {
      store.commit('clearBannerPositions');
    },
  },
};
