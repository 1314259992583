export default [
  {
    path: '',
    redirect: '/opportunities',
  },
  {
    path: 'opportunities',
    component: () => import('@/pages/opportunities.vue'),
  },
  {
    alias: 'opportunities/addedit',
    path: 'opportunities/addedit/:id',
    component: () => import('@/pages/opportunities/addedit.vue'),
  },
];
