import axios from 'axios';
import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js';;

axios.interceptors.request.use((config) => {
  // Do something before request is sent
  LoadingBar.start();
  return config;
}, (error) => {
  // Do something with request error
  LoadingBar.stop();
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  LoadingBar.stop();
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  LoadingBar.stop();

  let errorMessage = error?.response?.data?.message;

  if (errorMessage && typeof errorMessage === 'string') errorMessage = [errorMessage];

  if (Array.isArray(errorMessage)) {
    Notify.create({
      type: 'negative',
      caption: 'Ошибка API',
      message: errorMessage.join(' | '),
      timeout: 10000,
      closeBtn: true,
    });
  }

  console.error(error);

  return Promise.reject(error);
});
