/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

// @ts-ignore
import { CONFIG } from '@/config/index.js';

// @ts-ignore
import { currentToken } from '@/store/user';
// import {BaseURL} from './models';
import { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

export const BASE_PATH = '/'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}
export interface baseAxiosParamCreator {
  methodApi: string,
  urlMethod: string,
  body?: unknown,
  image?: unknown
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;
  protected axios: AxiosInstance = globalAxios

  constructor(configuration?: Configuration, protected basePath: string = BASE_PATH) {
    configuration = {
      basePath: CONFIG.apiBasePath,
      baseOptions: {
        headers: {
          'Authorization': `Bearer ${currentToken}`
        }
      }
    };

    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }

  public axiosParamCreator(parameters: baseAxiosParamCreator) {
    let {methodApi, urlMethod, body, image} = parameters

    const requestOptions = {...this.configuration?.baseOptions,  method:methodApi };
    const formData = new FormData();
    
    if(image) {
      formData.append('image', image as any);
      requestOptions.data = formData;
    } else if(body){
      requestOptions.data = body
    }

    return this.axios.request({
      ...requestOptions,
      url:`${this.configuration?.basePath}${urlMethod}`
    })

  }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: 'RequiredError' = 'RequiredError';

  constructor(public field: string, msg?: string) {
    super(msg);
  }
}
