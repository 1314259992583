import { IPartnerPosition } from '@/api/models/partner-position-interface';

export class PartnerPosition implements IPartnerPosition {
  constructor(
    public account_no?: number,
    public position?: number,
    public logo_url?: string,
    public name?: string,
  ) {
  }
}
