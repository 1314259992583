import { CONFIG } from '@/config';

const authFetch = async (method, data) => {
  const response = await fetch(`${CONFIG.authBasePath}${method}`, {
    method: 'POST',
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data),
  });

  return response.json();
};

export const authRequest = async (phone) => authFetch('request', { phone });
export const authVerify = async ({
  phone,
  id,
  pin,
}) => authFetch('verify', {
  phone,
  id,
  pin,
});
