export default {
  links: [
    {
      title: 'Веб-сайт',
      platform: 'website',
      icon: 'fas fa-link',
    },

    {
      title: 'ВКонтакте',
      platform: 'vk',
      icon: 'fab fa-vk',
    },

    {
      title: 'Одноклассники',
      platform: 'ok',
      icon: 'fab fa-odnoklassniki',
    },

    {
      title: 'Дзен',
      platform: 'yandex-dzen',
      icon: 'fab fa-yandex',
    },
    {
      title: 'Twitter',
      platform: 'twitter',
      icon: 'fab fa-twitter',
    },
    {
      title: 'Telegram',
      platform: 'telegram',
      icon: 'fab fa-telegram',
    },
    {
      title: 'Приложение в App Store',
      platform: 'ios',
      icon: 'fab fa-app-store-ios',
    },
    {
      title: 'Приложение в Google Play',
      platform: 'android',
      icon: 'fab fa-google-play',
    },
  ],
};
