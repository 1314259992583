<template>
  <canvas></canvas>
</template>

<script>

let isActive = true;

export default {
  name: 'background',
  props: {
    speed: {
      type: String,
      default() {
        return '1';
      },
    },

    timeShift: {
      type: String,
      default() {
        return '1';
      },
    },

    scale: {
      type: String,
      default() {
        return '1';
      },
    },
  },

  unmounted: () => isActive = false,

  data() {
    return {
      vShader: `attribute vec3 pos;
                void main() {
                    gl_Position=vec4(pos, 1.0);
                }`,
      fShader: `precision mediump float;
                #define ITERS 22
                uniform float time;
                uniform vec2 resolution;


                vec2 R(vec2 p,float a) {
                    return vec2( p.x*cos(a) + p.y*sin(a),
                                -p.x*sin(a) + p.y*cos(a));
                }

                float julia(vec2 c,vec2 c1, float max){
                    float xx = c.x * c.x;
                    float yy = c.y * c.y;
                    float xy = c.x * c.y;
                    float s = xx + yy;
                    for (int i = ITERS;i>0;i--){
                        c=vec2(xy + c.y - c.x, xx + yy * xy - c.y) + c1;
                        xx = c.x * c.x;
                        yy = c.y * c.y;
                        xy = c.x * c.y;
                        s = xx + yy;
                        if(s>=max || i == 1)return float(ITERS - i)/float(ITERS);
                    }
                    return 0.0;
                }


                void main() {

                    vec2 c0 = (gl_FragCoord.xy / resolution) - vec2(0.5,0.5);
                    vec2 c  = R(c0, time * 0.002) * 1.5;
                    float j = julia(c + cos(time*.2) / 1.7,c + sin(time*.4) / 1.7, 16.0);
                    float r = j * sin(c.x + time * 1.5);
                    float g = j * sin(c.y + time * 1.2 + 1.0);
                    float b = j;
                    gl_FragColor = vec4(r, g, b, 1.0);
                }`,
    };
  },

  mounted() {
    isActive = true;

    const a = this.$el;
    const w = a.width = innerWidth * this.scale;
    const h = a.height = innerHeight * this.scale;

    const g = a.getContext('webgl') || a.getContext('experimental-webgl');
    g.enable(g.DEPTH_TEST);
    g.depthFunc(g.LEQUAL);

    // a square
    function sq(s) {
      s = s || 1;
      return [s, s, 0, -s, s, 0, s, -s, 0, -s, -s, 0];
    }

    // a cube
    function qb(s, r, f, v, i, j) {
      f = '013321126651236673374403014145546674';
      v = '000100110010001101111011';
      for (r = [], s = s || 1, i = f.length; i--;) {
        Array.prototype.push.apply(r, (function (j) {
          return [
            v[j] == 1 ? s : -s,
            v[j + 1] == 1 ? s : -s,
            v[j + 2] == 1 ? s : -s,
          ];
        }(f[i] * 3)));
      }
      return r;
    }

    // Identity Matrix
    function mI() {
      return [1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1];
    }

    // Transformation matrix
    function mT(x, y, z, r) {
      return r = mI(), r.splice(12, 3, x, y, z), r;
    }

    // Scale matrix
    function mS(x, y, z) {
      return [x, 0, 0, 0,
        0, y, 0, 0,
        0, 0, z, 0,
        0, 0, 0, 1];
    }

    // Matrix multiplication
    function mX(a, b, c, i, j, k, l) {
      c = [];
      for (i = l = Math.sqrt(a.length) | 0; i--;) {
        for (k = l; k--;) {
          for (j = l; j--;) {
            if (!c[i + k * l]) c[i + k * l] = 0;
            c[i + k * l] += a[i + j * l] * b[j + k * l];
          }
        }
      }
      return c;
    }

    // Matrix determinant (until 3x3), todo: gaussian elimination for >3x3 ;)
    function mDet(a, n) {
      if (n = Math.sqrt(a) < 4) {
        return [1, a[0], a[0] * a[3] - a[2] * a[1],
          a[0] * a[4] * a[8] + a[3] * a[7] * a[2] + a[6] * a[1] * a[5]
          - a[6] * a[1] * a[5] - a[3] * a[1] * a[8] - a[0] * a[7] * a[5]][n | 0];
      }
    }

    // Matrix for rotation around x-axis
    function mRx(a) {
      return [1, 0, 0, 0,
        0, Math.cos(a), Math.sin(a), 0,
        0, -Math.sin(a), Math.cos(a), 0,
        0, 0, 0, 1];
    }

    // Matrix for rotation around y-axis
    function mRy(a) {
      return [Math.cos(a), 0, -Math.sin(a), 0,
        0, 1, 0, 0,
        Math.sin(a), 0, Math.cos(a), 0,
        0, 0, 0, 1];
    }

    // Matrix for rotation around z-axis
    function mRz(a) {
      return [Math.cos(a), Math.sin(a), 0, 0,
        -Math.sin(a), Math.cos(a), 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1];
    }

    // Vector cross product
    function vX(a, b) {
      return [a[1] * b[2] - a[2] * b[1],
        a[2] * b[0] - a[0] * b[2],
        a[0] * b[1] - a[1] * b[0]];
    }

    // Vector length
    function vL(v) {
      return Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
    }

    // Unit vector
    function v1(v) {
      return l = vL(v), [v[0] / l, v[1] / l, v[2] / l];
    }

    // glOrtho(left, right, bottom, top, zNear, zFar)
    function ortho(l, r, b, t, zn, zf, tx, ty, tz) {
      return tx = -(r + l) / (r - l),
      ty = -(t + b) / (t - b),
      tz = -(zf + zn) / (zf - zn),
      [2 / (r - l), 0, 0,
        0, 0, 2 / (t - b), 0,
        0, 0, 0, -2 / (zf - zn),
        0, tx, ty, tz, 1];
    }

    // glFrustum(left, right, bottom, top, zNear, zFar)
    function frustum(l, r, b, t, zn, zf, t1, t2, t3, t4) {
      return t1 = 2 * zn, t2 = r - l, t3 = t - b, t4 = zf - zn,
      [t1 / t2, 0, 0, 0,
        0, t1 / t3, 0, 0,
        (r + l) / t2, (t + b) / t3, (-zf - zn) / t4, -1,
        0, 0, (-t1 * zf) / t4, 0];
    }

    // gluPerspective(fieldOfView, aspectRatio, zNear, zFar)
    function perspective(fovy, ar, zn, zf, x, y) {
      return y = zn * Math.tan(fovy * Math.PI / 360),
      x = y * ar, frustum(-x, x, -y, y, zn, zf);
    }

    // gluLookAt(eyeX, eyeY, eyeZ, centerX, centerY, centerZ, upX, upY, upZ)
    function lookAt(ex, ey, ez, cx, cy, cz, ux, uy, uz, c, u, x, y, z) {
      return c = [cx, cy, cz],
      u = [ux, uy, uz],
      z = v1([ex - cx, ey - cy, ez - cz]),
      x = v1(vX(u, z)), y = v1(vX(z, x)),
      [x[0], y[0], z[0], 0,
        x[1], y[1], z[1], 0,
        x[2], y[2], z[2], 0,
        0, 0, 0, 1];
    }

    // create shader
    // $sh(domelement)
    function $sh(el, s) {
      s = g.createShader(/gl_Position/.test(el) ? g.VERTEX_SHADER : g.FRAGMENT_SHADER);
      g.shaderSource(s, el);
      g.compileShader(s);
      if (!g.getShaderParameter(s, g.COMPILE_STATUS)) {
        throw Error(`${el}: ${g.getShaderInfoLog(s)}`);
      }
      return s;
    }

    // create or switch shader program
    // define: $prog(glContext, array of shader dom elements, [array of attributes])
    // switch: $prog(glContext, program, [obj of uniforms], [obj of buffers])
    function $prog(g, s, a, c, p, P, i) {
      window.g = g;
      P = 'Program';
      if (/WebGLProgram/.test(s)) {
        // switch program
        g[`use${P}`](window.p = s);
        if (a && a.length) for (i = a.length; i--;) $attr(a[i]);
        return p;
      }
      // define program
      p = g[`create${P}`]();
      s.map((s) => {
        g.attachShader(p, $sh(s));
      });
      g[`link${P}`](p);
      g[`use${P}`](p);
      // pollute the global namespace a bit.
      window.p = p;
      // set attributes
      if (a && a.length) for (i = a.length; i--;) $attr(a[i]);
      return p;
    }

    // create buffer
    // $buf([1,0,1,0,...])
    function $buf(v, b) {
      b = g.createBuffer();
      g.bindBuffer(g.ARRAY_BUFFER, b);
      g.bufferData(g.ARRAY_BUFFER, new Float32Array(v), g.STATIC_DRAW);
      return b;
    }

    // get reference to Attribute
    // $attr("name")
    function $attr(l, r) {
      return r = g.getAttribLocation(p, l), g.enableVertexAttribArray(r), r;
    }

    function $attrOff(l, r) {
      return r = g.getAttribLocation(p, l), g.disableVertexAttribArray(r), r;
    }

    // bind attribute to buffer
    // $bind(attr, buffer)
    function $bind(a, b, s, t, n) {
      t = t || g.FLOAT;
      n = n || false;
      g.bindBuffer(g.ARRAY_BUFFER, b);
      g.vertexAttribPointer(typeof a === 'string' ? g.getAttribLocation(p, a) : a, s, t, n, 0, 0);
    }

    // set uniform matrix
    function $uniM(n, d, l) {
      l = g.getUniformLocation(p, n);
      g[`uniformMatrix${sqrt(d.length)}fv`](l, false, new Float32Array(d));
      return l;
    }

    // set uniform vector
    function $uniV(n, d, l) {
      l = g.getUniformLocation(p, n);
      g[`uniform${d.length}fv`](l, new Float32Array(d));
      return l;
    }

    // set uniform float
    function $uni(n, d, l) {
      l = g.getUniformLocation(p, n);
      if (typeof (d) === 'number') g.uniform1f(l, d);
      return l;
    }

    // Create texture from image
    function $tex2d(img, p, i, t) {
      p = p || [
        g.TEXTURE_2D, g.TEXTURE_WRAP_S, g.CLAMP_TO_EDGE,
        g.TEXTURE_2D, g.TEXTURE_WRAP_T, g.CLAMP_TO_EDGE,
        g.TEXTURE_2D, g.TEXTURE_MIN_FILTER, g.LINEAR,
        g.TEXTURE_2D, g.TEXTURE_MAG_FILTER, g.LINEAR,
      ];
      t = g.createTexture();
      g.bindTexture(g.TEXTURE_2D, t);
      for (i = (p.length / 3) | 0; i--;) gl.texParameteri(p[i * 3], p[i * 3 + 1], p[i * 3 + 2]);
      g.texImage2D(g.TEXTURE_2D, 0, g.RGBA, g.RGBA, g.UNSIGNED_BYTE, img);
    }

    // Simple image preloader
    // load images (u = array of urls), call back f([array of image objects]) when ready
    function $load(u, f, i, j, k) {
      for (k = j = u.length; j--;) {
        i = new Image(),
        i.src = u[j],
        i.onload = function () {
          if (!--k) f(u);
        },
        u[j] = i;
      }
    }

    $prog(g, [this.vShader, this.fShader]);
    const posBuf = $buf([1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1, 0]);
    const pos = $attr('pos');
    const speed = parseFloat(this.speed);
    const timeShift = parseFloat(this.timeShift);

    ~(function scene(time) {
      time = time * 1e-3 * speed;

      // clear screen
      g.clearColor(1 / 5, 1 / 5, 1 / 5, 1);
      g.clear(g.COLOR_BUFFER_BIT | g.DEPTH_BUFFER_BIT);

      // bind attributes to buffers
      $bind(pos, posBuf, 3);

      // set uniforms
      $uni('time', timeShift + time);
      $uniV('resolution', [w, h]);

      // draw
      g.drawArrays(g.TRIANGLE_STRIP, 0, 4);
      if (isActive) {
        requestAnimationFrame(scene);
      }
    }(0));
  },
};
</script>

<style scoped>
canvas {
  position: absolute;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
