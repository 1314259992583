/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ForbiddenException } from '../models';
import { GetBalanceResponseDto } from '../models';
import { GetIncomeResponseDto } from '../models';
import { GetOperationsResponseDto } from '../models';
import { GetRolesResponseDto } from '../models';
import { OrganizationSearchResponseDto } from '../models';
import { RedeemCodeByPersonPayloadDto } from '../models';

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить список организаций в которых числится пользователь
     * @param {Array&lt;string&gt;} [role] Фильтр по ролям
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetPersonOrganizations: async (role?: Array<string>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/user/organizations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (role) {
        localVarQueryParameter['role'] = role;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список ролей пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetRoles: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/user/roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение баланса пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetUserBalance: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/user/balance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Сумма доходов за указанный промежуток времени (по умолчанию за календарный день)
     * @param {string} [start] Начальная дата создания записей
     * @param {string} [currency] Тип валюты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetUserIncome: async (start?: string, currency?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/user/income`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (currency !== undefined) {
        localVarQueryParameter['currency'] = currency;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список операций (от имени пользователя)
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetUserTransactions: async (skip?: number, take?: number, start?: string, end?: string, order?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/user/operations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отметить промокод как использованный
     * @param {RedeemCodeByPersonPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerRedeemCode: async (body: RedeemCodeByPersonPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling personControllerRedeemCode.');
      }
      const localVarPath = `/api/2.0/user/redeem-code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить список организаций в которых числится пользователь
     * @param {Array&lt;string&gt;} [role] Фильтр по ролям
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGetPersonOrganizations(role?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationSearchResponseDto>>> {
      const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration)
        .personControllerGetPersonOrganizations(role, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список ролей пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGetRoles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRolesResponseDto>> {
      const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration)
        .personControllerGetRoles(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получение баланса пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGetUserBalance(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalanceResponseDto>> {
      const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration)
        .personControllerGetUserBalance(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Сумма доходов за указанный промежуток времени (по умолчанию за календарный день)
     * @param {string} [start] Начальная дата создания записей
     * @param {string} [currency] Тип валюты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGetUserIncome(start?: string, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIncomeResponseDto>> {
      const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration)
        .personControllerGetUserIncome(start, currency, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список операций (от имени пользователя)
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGetUserTransactions(skip?: number, take?: number, start?: string, end?: string, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperationsResponseDto>> {
      const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration)
        .personControllerGetUserTransactions(skip, take, start, end, order, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Отметить промокод как использованный
     * @param {RedeemCodeByPersonPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerRedeemCode(body: RedeemCodeByPersonPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration)
        .personControllerRedeemCode(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Получить список организаций в которых числится пользователь
     * @param {Array&lt;string&gt;} [role] Фильтр по ролям
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetPersonOrganizations(role?: Array<string>, options?: any): AxiosPromise<Array<OrganizationSearchResponseDto>> {
      return UserApiFp(configuration)
        .personControllerGetPersonOrganizations(role, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список ролей пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetRoles(options?: any): AxiosPromise<GetRolesResponseDto> {
      return UserApiFp(configuration)
        .personControllerGetRoles(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение баланса пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetUserBalance(options?: any): AxiosPromise<GetBalanceResponseDto> {
      return UserApiFp(configuration)
        .personControllerGetUserBalance(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Сумма доходов за указанный промежуток времени (по умолчанию за календарный день)
     * @param {string} [start] Начальная дата создания записей
     * @param {string} [currency] Тип валюты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetUserIncome(start?: string, currency?: string, options?: any): AxiosPromise<GetIncomeResponseDto> {
      return UserApiFp(configuration)
        .personControllerGetUserIncome(start, currency, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список операций (от имени пользователя)
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetUserTransactions(skip?: number, take?: number, start?: string, end?: string, order?: string, options?: any): AxiosPromise<GetOperationsResponseDto> {
      return UserApiFp(configuration)
        .personControllerGetUserTransactions(skip, take, start, end, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отметить промокод как использованный
     * @param {RedeemCodeByPersonPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerRedeemCode(body: RedeemCodeByPersonPayloadDto, options?: any): AxiosPromise<void> {
      return UserApiFp(configuration)
        .personControllerRedeemCode(body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Получить список организаций в которых числится пользователь
   * @param {Array&lt;string&gt;} [role] Фильтр по ролям
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public personControllerGetPersonOrganizations(role?: Array<string>, options?: any) {
    return UserApiFp(this.configuration)
      .personControllerGetPersonOrganizations(role, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список ролей пользователя
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public personControllerGetRoles(options?: any) {
    return UserApiFp(this.configuration)
      .personControllerGetRoles(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение баланса пользователя
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public personControllerGetUserBalance(options?: any) {
    return UserApiFp(this.configuration)
      .personControllerGetUserBalance(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Сумма доходов за указанный промежуток времени (по умолчанию за календарный день)
   * @param {string} [start] Начальная дата создания записей
   * @param {string} [currency] Тип валюты
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public personControllerGetUserIncome(start?: string, currency?: string, options?: any) {
    return UserApiFp(this.configuration)
      .personControllerGetUserIncome(start, currency, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список операций (от имени пользователя)
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public personControllerGetUserTransactions(skip?: number, take?: number, start?: string, end?: string, order?: string, options?: any) {
    return UserApiFp(this.configuration)
      .personControllerGetUserTransactions(skip, take, start, end, order, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отметить промокод как использованный
   * @param {RedeemCodeByPersonPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public personControllerRedeemCode(body: RedeemCodeByPersonPayloadDto, options?: any) {
    return UserApiFp(this.configuration)
      .personControllerRedeemCode(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
