import { UserApi } from '@/api/apis/user-api.ts';
import { authRequest, authVerify } from '@/auth';
import { userStorage } from '@/lib/userStorage';

export let currentToken = '';

export default {
  state: {
    state: 'init', // init , requested , loggedIn
    authRequest: {
      id: '',
      message: '',
      timeout: 60,
    },
    phone: '',
    token: '',
    loggedIn: false,
    roles: [],
    scope: 0,
  },

  actions: {
    async authRequest(store, phone) {
      try {
        const res = await authRequest(phone);

        if (res?.result !== true) {
          throw new Error(res?.message);
        }

        store.commit('setPhone', phone);
        store.state.authRequest = {
          ...res,
        };
        store.state.state = 'requested';
      } catch (e) {
        console.log(e);
        store.commit('setLoggedIn', false);
        store.state.authRequest.message = e.message;
      }
    },

    async authVerify(store, pin) {
      try {
        const res = await authVerify({
          phone: store.state.phone,
          id: store.state.authRequest.id,
          pin,
        });

        if (res?.result !== true) {
          throw new Error(res?.message);
        }

        store.commit('setToken', res.token);
        store.dispatch('login');
      } catch (e) {
        console.log(e);
        store.commit('setLoggedIn', false);
        store.state.authRequest.message = e.message;
      }
    },

    async login(store) {
      try {
        await new UserApi().personControllerGetUserBalance();
        store.commit('setLoggedIn', true);
        store.dispatch('getRoles');
      } catch (e) {
        console.log(e);
        store.commit('setLoggedIn', false);
        store.dispatch('logout');
      }
    },

    async getRoles({
      commit,
    }) {
      try {
        const roles = await new UserApi().personControllerGetRoles();
        commit('setRoles', roles?.data?.roles);
      } catch (e) {
        console.log(e);
      }
    },

    async logout(store) {
      store.commit('setPhone', undefined);
      store.commit('setToken', undefined);
      store.commit('setLoggedIn', false);
    },
  },

  mutations: {
    setPhone(state, phone) {
      state.phone = phone;
      userStorage.phone = phone;
    },

    setToken(state, token) {
      if (window.ENV_DEBUG_JWT) {
        token = window.ENV_DEBUG_JWT;
      }

      state.token = token;
      userStorage.token = token;

      currentToken = token;
    },

    setLoggedIn(state, status) {
      state.loggedIn = status;

      if (status) {
        state.state = 'loggedIn';
      } else {
        state.state = 'init';
      }
    },

    setRoles(state, roles) {
      state.roles = roles;
    },

    setScope(state, scope) {
      state.scope = scope;
      userStorage.scope = scope;
    },
  },

  getters: {
    loggedIn: (state) => state.loggedIn,
    isAdmin: (state) => !!state.roles.find(({ name }) => name === 'admin'),
    isEmployee: (state) => !!state.roles.find(
      ({ name }) => name === 'employee',
    ),
    accessDenied: (state, getters) => !getters.isAdmin && !getters.isEmployee,
    rolesSet: (state) => new Set(
      state.roles.filter(({ scope }) => scope === state.scope).map(
        ({ name }) => name,
      ),
    ),
  },

  init(store) {
    if (userStorage.scope !== undefined) {
      store.commit('setScope', userStorage.scope);
    }

    if (userStorage.phone !== undefined) {
      store.commit('setPhone', userStorage.phone);
    }

    if (userStorage.token !== undefined) {
      store.commit('setToken', userStorage.token);
      store.dispatch('login');
    }
  },
};
