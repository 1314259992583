export default [
  {
    alias: 'offers/addedit/:vendor_no',
    path: 'offers/addedit/:vendor_no/:offer_id',
    component: () => import('@/pages/offers/addedit.vue'),
  },
  {
    alias: 'offers/positions',
    path: 'offers/positions',
    component: () => import('@/pages/offers/positions.vue'),
  },
];
