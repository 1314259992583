<template>
  <q-card class="row q-pa-sm" square>
    <span class="text-body2">
      © 2023 СпасибоРубль.

      <q-btn icon="dark_mode" round size="xs" @click="toggleDarkMode">
        <q-tooltip :offset="[10, 10]" class="text-black shadow-4">
          Ночной режим
        </q-tooltip>
      </q-btn>
    </span>
  </q-card>
</template>

<script>
import { defineComponent } from 'vue';
import { Dark } from 'quasar';
import { userStorage } from '@/lib/userStorage';

export default defineComponent({
  name: 'Footer',

  components: {},

  setup() {
    Dark.set(userStorage.darkMode);

    return {
      toggleDarkMode() {
        Dark.toggle();
        userStorage.darkMode = Dark.isActive;
      },
    };
  },
});
</script>
