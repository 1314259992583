import { createRouter, createWebHistory } from 'vue-router';
import { importFolder } from '@/lib/abutils';

const menuRoutes = [];
importFolder(require.context('./menu/', true, /\.js$/), (key, items) => {
  menuRoutes.push(...items);
});

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      ...menuRoutes,
      {
        path: '/Charts',
        component: () => import('@/pages/Charts.vue'),
      },
    ],
  },

  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/Error404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

window.router = router;

export default router;
