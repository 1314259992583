import { IBanner } from '@/api/models/banner-interface';

export class Banner implements IBanner {
  constructor(
    public link?: string,
    public image?: string,
    public id?: number,
    public position?: number,
  ) {
  }
}
