export default [
  {
    path: 'organizations',
    component: () => import('@/pages/organizations.vue'),
  },
  {
    alias: 'organizations/addedit',
    path: 'organizations/addedit/:id',
    component: () => import('@/pages/organizations/addedit.vue'),
  },
  {
    alias: 'organizations/details',
    path: 'organizations/details/:id',
    component: () => import('@/pages/organizations/details.vue'),
  },
  {
    alias: 'organizations/positions',
    path: 'organizations/positions',
    component: () => import('@/pages/organizations/positions.vue'),
  },
];
