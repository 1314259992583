import { PartnerPosition } from '@/entities/partner-position-entity';

export default {
  state: {
    data: [] as PartnerPosition[],
    positions: [] as PartnerPosition[],
    // editItem: new PartnerPosition()
  },
  mutations: {
    updatePartners(state: { data: PartnerPosition[]; }, partners: PartnerPosition[]) {
      state.data = partners;
    },
    updatePartnersDropped(state: { data: PartnerPosition[]; positions: any; }, partners: any[]) {
      state.data = partners.map((el: PartnerPosition, index) => (new PartnerPosition(
        el.account_no,
        ++index,
        el.logo_url,
        el.name,
      )));// { ...el, position: ++index }
      state.positions = state.data.map((el) => (new PartnerPosition(el.account_no, el.position)));// { account_no: el.account_no, position: el.position }
    },
    // editPartnersItemUpdate(state: { editItem: PartnerPosition; }, item: PartnerPosition) {
    // 	if( item ){
    // 		state.editItem =  new PartnerPosition(item.account_no, item.position)
    // 	} else {
    // 		state.editItem = new PartnerPosition()
    // 	}
    // },
    // deletePartnersItem(state: { data: PartnerPosition[]; }, itemId: number) {
    // 	state.data = state.data.filter((el) => el.account_no !== itemId)
    // 	.map((el, index) => ({ ...el, position: ++index }))
    // },
    clearPartnersPositions(state: { positions: PartnerPosition[]; }) {
      state.positions = [];
    },
  },
  actions: {
    updatePartners(store: any, partners: any) {
      store.commit('updatePartners', partners);
    },
    updatePartnersDropped(store: any, partners: any) {
      store.commit('updatePartnersDropped', partners);
    },
    // editPartnersItemUpdate(store: any, item: any) {
    // 	store.commit('editPartnersItemUpdate', item)
    // },
    // deleteItem(store: any, itemId: any) {
    // 	store.commit('deleteItem', itemId)
    // },
    clearPartnersPositions(store: any) {
      store.commit('clearPartnersPositions');
    },
  },
};
