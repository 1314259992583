import './abutils';

(function (root) {
  $.fn.$ = $.fn.find;
  $.fn.hook = function (method, fn) {
    const def = $.fn[method];

    if (def) {
      $.fn[method] = function () {
        const r = def.apply(this, arguments); // original method
        fn(this, method, arguments); // injected method
        return r;
      };
    }
  };

  root.toggleFullScreen = function () {
    if (!root.document || !root.document.documentElement) {
      return;
    }

    const element = document.body;

    const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen = element.requestFullScreen
      || element.webkitRequestFullScreen
      || element.mozRequestFullScreen
      || function () {
        return false;
      };
    document.cancelFullScreen = document.cancelFullScreen
      || document.webkitCancelFullScreen
      || document.mozCancelFullScreen
      || function () {
        return false;
      };

    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };
}(window));
