export function toHumanDate(input) {
  if (!input) {
    return '-';
  }

  return new Intl.DateTimeFormat('ru-RU').format(new Date(input));
}

export function toHumanDateTime(input) {
  if (!input) {
    return '-';
  }

  return new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }).format(new Date(input));
}
