import { CategoryEntity } from '@/entities/category-entity';

export default {
  state: {
    data: [],
    // positions: [],
    editItem: new CategoryEntity(),
  },
  mutations: {
    updateCategories(state: { data: any; }, banners: CategoryEntity[]) {
      state.data = banners;
    },
    // updateCategoriesDropped(state: { data: CategoryEntity[]; positions: any; }, banners: any[]) {
    // 	state.data = banners.map((el: CategoryEntity, index) => (new CategoryEntity(el.link, el.image, el.id, ++index)))
    // 	state.positions = state.data.map((el) => ({ id: el.id, position: el.position }))
    // },
    editCategoryItemUpdate(state: { editItem: CategoryEntity; }, item: CategoryEntity) {
      if (item) {
        state.editItem = new CategoryEntity(item.name, item.description);
      } else {
        state.editItem = new CategoryEntity();
      }
    },
    deleteCategoryItem(state: { data: CategoryEntity[]; }, itemId: string) {
      state.data = state.data.filter((el) => el.name !== itemId);
      // .map((el, index) => (new CategoryEntity(el.link, el.image, el.id, ++index)))//{ ...el, position: ++index }
    },
    // clearCategoryPositions(state: { positions: CategoryEntity[]; }) {
    // 	state.positions = []
    // }
  },
  actions: {
    updateCategories(store: any, banners: any) {
      store.commit('updateCategories', banners);
    },
    updateCategoriesDropped(store: any, banners: any) {
      store.commit('updateCategoriesDropped', banners);
    },
    editCategoryItemUpdate(store: any, item: any) {
      store.commit('editCategoryItemUpdate', item);
    },
    deleteCategoryItem(store: any, itemId: any) {
      store.commit('deleteCategoryItem', itemId);
    },
    // clearCategoryPositions(store: any) {
    // 	store.commit('clearCategoryPositions')
    // }
  },
};
