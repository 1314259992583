export const noop = () => {
};

export const importFolder = (req, cb = noop) => {
  const files = req.keys();
  files.forEach((file) => {
    cb(file.replace(/(\.\/|\..+)/g, ''), req(file).default);
  });
};

(function (root) {
  const _exports = {
    math() {
      /**
       * @memberOf Math
       * @method clamp
       * @param {number|*} x
       * @param {number} [min]
       * @param {number} [max]
       * @return {number}
       */
      const clamp = (x, min = -Infinity, max = Infinity) => {
        x = parseFloat(x);

        if (isNaN(x)) {
          return 0;
        }

        return Math.max(Math.min(x, max), min);
      };

      Object.defineProperty(Math, 'clamp', {
        value: clamp,
        writable: false,
        enumerable: false,
        configurable: false,
      });
    },

    number() {
      const numberProto = Number.prototype;

      /**
       * @memberOf Number.prototype
       * @return {number}
       * @param min
       * @param max
       */
      numberProto.clamp = function (min, max) {
        return Math.min(Math.max(this, min), max);
      };
    },

    string() {
      /**
       * @type {String.prototype}
       */
      const stringProto = String.prototype;
      const htmlEntityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        '\'': '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;',
      };

      /**
       * @memberOf String.prototype
       * @return {string}
       */
      stringProto.htmlSpecialChars = function () {
        return this.replace(/[&<>"'`=\/]/g, (s) => htmlEntityMap[s]);
      };

      /**
       * @memberOf String.prototype
       * @return {string}
       */
      stringProto.capitalize = function () {
        return this.charAt(0)
          .toUpperCase() + this.slice(1);
      };

      /**
       * @memberOf String.prototype
       * @return {string}
       */
      stringProto.lowerize = function () {
        return this.charAt(0)
          .toLowerCase() + this.slice(1);
      };

      /**
       * @memberOf String.prototype
       * @param search
       * @return {string}
       * @param replace
       */
      stringProto.replaceAll = function (search, replace) {
        if (!replace) {
          replace = '';
        }

        return this.split(search)
          .join(replace);
      };

      /**
       * @memberOf String.prototype
       * @param search
       * @return {boolean}
       */
      stringProto.has = function (search = '') {
        return this.indexOf(search) !== -1;
      };

      /**
       * @memberOf String.prototype
       * @return {string}
       */
      stringProto.hashCode = function () {
        let hash = 0;

        if (this.length === 0) {
          return hash;
        }
        for (let i = 0; i < this.length; i++) {
          const char = this.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash &= hash;
        }

        return btoa(unescape(encodeURIComponent(this)))
          .substr(0, 5) + Math.abs(hash)
          .toString();
      };
    },

    functions() {
      /**
       * @global
       * @function importFolder
       * @param req
       * @param {function} cb
       * */
      root.importFolder = importFolder;

      /**
       * Empty function.
       *
       * @global
       * @function noop
       * */
      root.noop = noop;

      /**
       * Proxy that resolves after N ms of time.
       *
       * @global
       * @function sleep
       * @param ms
       * @param {function|Object} forceResolve - function for resolving
       * promise before time.
       * @return {Promise<number>} ms
       */
      root.sleep = (ms = 0, forceResolve = null) => new Promise(((resolve) => {
        if (!ms) {
          return resolve(0);
        }

        const tm = setTimeout(() => {
          clearTimeout(tm);
          resolve(ms);
        }, ms);

        if (forceResolve && forceResolve instanceof Promise) {
          forceResolve.then(() => {
            clearTimeout(tm);
            resolve(ms);
          });
        }
      }));

      /**
       * Wait for resolver return positive value.
       *
       * @global
       * @param resolver
       * @param timeout
       * @param step
       * @return {Promise} - Resolver result
       */
      root.waitForTrue = async (resolver = noop, timeout = 0, step = 100) => {
        if (typeof resolver !== 'function') {
          return false;
        }

        timeout = Math.max(Math.abs(parseInt(timeout) || 0), 0);
        step = Math.max(Math.abs(parseInt(step) || 100), 10);

        if (timeout) {
          step = Math.min(step, timeout);
        }

        const startTS = Date.now();

        let result = resolver(0);
        const isPromise = result instanceof Promise;

        if (isPromise) {
          result = await result;
        }

        do {
          if (result) {
            return result;
          }

          await root.sleep(step);

          if (timeout && (Date.now() - startTS) >= timeout) {
            return false;
          }

          if (isPromise) {
            result = await resolver(Date.now() - startTS);
          } else {
            result = resolver(Date.now() - startTS);
          }
        } while (true);
      };
    },

    json() {
      if (!JSON.tryParse) {
        /**
         * @return {undefined | *}
         * @param text
         */
        JSON.tryParse = function (text = '') {
          try {
            return JSON.parse(text);
          } catch (e) {

          }

          return undefined;
        };
      }
    },

    array() {
      if (typeof Array.prototype.remove !== 'function') {
        Object.defineProperty(Array.prototype, 'remove', {
          enumerable: false,
          writable: false,
          value: /**
           * Removes element from array.
           *
           * @method remove
           * @memberOf Array.prototype
           * @this {Array<Type>}
           * @param {Type} item
           * @return {boolean|Type} item
           */
          function (item) {
            const index = this.indexOf(item);

            if (index >= 0) {
              return this.splice(index, 1);
            }

            return false;
          },
        });
      }
    },

    object() {
      if (!Object.getClassName) {
        Object.getClassName = (object) => {
          if (typeof object === 'function') {
            return object.name;
          }

          if (typeof object === 'object') {
            return (object.constructor && object.constructor.name) || (object.__proto__ && Object.getClassName(object.__proto__));
          }

          return typeof object;
        };
      }

      if (!Object.merge) {
        const isObject = (item) => item && typeof item === 'object' && !Array.isArray(item);

        /**
         * Deep analog of Object.assign.
         * @method Object.merge
         * @param target
         * @param sources
         * @return {*}
         */

        Object.merge = (target, ...sources) => {
          if (!sources.length) {
            return target;
          }
          const source = sources.shift();

          if (isObject(target) && isObject(source)) {
            for (const key in source) {
              if (isObject(source[key]) && target[key]) {
                Object.merge(target[key], source[key]);
              } else {
                Object.assign(target, { [key]: source[key] });
              }
            }
          }

          return Object.merge(target, ...sources);
        };

        Object.merge = (...args) => $.extend(true, ...args);
      }

      if (!Object.onChange) {
        Object.onChange = function (obj, cb) {
          const handler = {
            get(target, property, receiver) {
              try {
                if (target && typeof target[property] === 'object') {
                  return new Proxy(target[property], handler);
                }
              } catch (err) {

              }

              return Reflect.get(target, property, receiver);
            },

            set(target, property, value, receiver) {
              let r;
              try {
                if (target && typeof target[property] === 'object' && !Array.isArray(target[property])) {
                  r = new Proxy(target[property], handler);
                }
              } catch (err) {

              }

              r = Reflect.set(target, property, value, receiver);

              try {
                cb();
              } catch (e) {

              }

              return r;
            },

            defineProperty(target, property, descriptor) {
              const r = Reflect.defineProperty(target, property, descriptor);

              try {
                cb();
              } catch (e) {

              }

              return r;
            },

            deleteProperty(target, property) {
              const r = Reflect.deleteProperty(target, property);

              try {
                cb();
              } catch (e) {

              }

              return r;
            },
          };

          return new Proxy(obj, handler);
        };
      }
    },
  };

  Object.keys(_exports)
    .forEach((k) => _exports[k](root));

  return _exports;
}(self));
