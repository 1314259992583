import { OfferPosition } from '@/entities/offer-position-entity';

export default {
  state: {
    data: [] as OfferPosition[],
    positions: [] as OfferPosition[],
    // editItem: new OfferPosition()
  },
  mutations: {
    updateOffers(state: { data: OfferPosition[]; }, offers: OfferPosition[]) {
      state.data = offers;
    },
    updateOffersDropped(state: { data: OfferPosition[]; positions: any; }, offers: any[]) {
      state.data = offers.map((el: OfferPosition, index) => (new OfferPosition(
        el.offer_id,
        ++index,
        el.organization_id,
        el.image_url,
        el.header,
        el.used,
        el.total
      )));// { ...el, position: ++index }
      state.positions = state.data.map((el) => (new OfferPosition(el.offer_id, el.position)));// { offer_id: el.offer_id, position: el.position }
    },
    // editOffersItemUpdate(state: { editItem: OfferPosition; }, item: OfferPosition) {
    // 	if( item ){
    // 		state.editItem =  new OfferPosition(item.account_no, item.position)
    // 	} else {
    // 		state.editItem = new OfferPosition()
    // 	}
    // },
    // deleteOffersItem(state: { data: OfferPosition[]; }, itemId: number) {
    // 	state.data = state.data.filter((el) => el.account_no !== itemId)
    // 	.map((el, index) => ({ ...el, position: ++index }))
    // },
    clearOffersPositions(state: { positions: OfferPosition[]; }) {
      state.positions = [];
    },
  },
  actions: {
    updateOffers(store: any, offers: any) {
      store.commit('updateOffers', offers);
    },
    updateOffersDropped(store: any, offers: any) {
      store.commit('updateOffersDropped', offers);
    },
    // editOffersItemUpdate(store: any, item: any) {
    // 	store.commit('editOffersItemUpdate', item)
    // },
    // deleteItem(store: any, itemId: any) {
    // 	store.commit('deleteItem', itemId)
    // },
    clearOffersPositions(store: any) {
      store.commit('clearOffersPositions');
    },
  },
};
