import { createStore } from 'vuex';
import partners from '@/store/partner-positions';
import offers from '@/store/offer-positions';
import categories from '@/store/categories';
import banners from './banners.ts';
import feedback from './feedback';
import user from './user';

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    feedback,
    banners,
    partners,
    offers,
    categories,
  },
});

export default store;

user.init && user.init(store);
