<template>
  <auth v-if="!loggedIn || accessDenied"></auth>
  <router-view v-else/>
  <!--  <router-view/>-->
</template>

<script>
import auth from '@/pages/auth';
import { mapGetters } from 'vuex';

export default {
  components: {
    auth,
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'accessDenied',
    ]),
  },
};
</script>
