// @ts-ignore
import './quasar-user-options';

import EE from 'eventemitter3';

import './config';
import './lib';

// @ts-ignore
import './app.js';

// @ts-ignore
self.EventBus = new EE();
