export default [
  {
    path: 'news',
    component: () => import('@/pages/news.vue'),
  },
  {
    alias: 'news/addedit',
    path: 'news/addedit/:id',
    component: () => import('@/pages/news/addedit.vue'),
  },
];
