export default [
  {
    path: 'campaigns',
    component: () => import('@/pages/campaigns.vue'),
  },
  {
    alias: 'campaigns/addedit',
    path: 'campaigns/addedit/:id',
    component: () => import('@/pages/campaigns/addedit.vue'),
  },
];
