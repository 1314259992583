import './styles/quasar.scss';
import lang from 'quasar/lang/ru.js';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';

import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Dark from 'quasar/src/plugins/Dark.js';import Loading from 'quasar/src/plugins/Loading.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js';;

window.Dark = Dark;
// console.log(Dark.isActive);
// Dark.set(false); // or false or "auto"

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    animations: 'all',
    dark: false,
  },
  extras: [
    'roboto-font',
  ],
  plugins: {
    AppFullscreen,
    Notify,
    Loading,
    LoadingBar,
  },
  lang,
};
