<template>
  <q-layout>
    <background scale=0.6 speed=0.105></background>

    <q-page-container>
      <q-page class="flex bg-image flex-center">
        <q-card class="auth-card">

          <q-card-section>
            <div class="text-center q-pt-sm">
              <div class="col text-h6 ellipsis">
                <q-icon name="person"/>
                Авторизация
              </div>
            </div>
          </q-card-section>
          <q-card-section>
            <q-form
                class="q-gutter-md"
                @submit="submit"
            >
              <q-input
                  v-if="state === 'init'"
                  v-model="phone"
                  :rules="[ val => phoneNumbers.length === 11 || 'Неподходящий номер телефона' ]"
                  autofocus
                  filled
                  label="Номер телефона"
                  lazy-rules
                  mask="(###) ### ## ##"
                  prefix="+7"
              />

              <q-input
                  v-if="state === 'requested'"
                  v-model="code"
                  autofocus
                  filled
                  label="Код"
                  lazy-rules
                  mask="####"
              />

              <q-badge v-if="state === 'requested' && authRequestInfo?.message" color="red"
                       v-text="authRequestInfo?.message"/>

              <div v-if="loggedIn && accessDenied" class="text-center">
                <q-badge class="q-ma-md" color="red">
                  Недостаточно прав.
                </q-badge>

                <q-btn :loading="loading"
                       class="full-width"
                       color="accent"
                       icon="verified_user"
                       label="Выйти"
                       type="button"
                       @click="logout"/>
              </div>
              <div v-else>
                <q-btn :loading="loading"
                       class="full-width"
                       color="primary"
                       icon="verified_user"
                       label="Вход"
                       type="button"
                       @click="submit"/>
              </div>
            </q-form>
          </q-card-section>
        </q-card>
      </q-page>
    </q-page-container>

    <dbr-footer style="position: fixed; bottom: 0; width: 100%"/>
  </q-layout>
</template>

<script>
import Footer from '@/components/Footer';
import Background from '@/components/ShaderBackground';
import { phoneToNumber } from '@/helpers/filters/phone';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'auth',
  components: {
    Background,
    dbrFooter: Footer,
  },
  data() {
    return {
      phone: '',
      code: '',
      loading: false,
    };
  },
  computed: {
    phoneNumbers() {
      return `7${phoneToNumber(this.phone)}`;
    },

    // ...mapState([
    //   'user/authRequest',
    //   'user/state',
    // ]),

    ...mapState({
      authRequestInfo: (state) => state.user.authRequest,
      state: (state) => state.user.state,
    }),

    ...mapGetters([
      'loggedIn',
      'accessDenied',
    ]),
  },
  methods: {
    ...mapActions([
      'authRequest',
      'authVerify',
      'logout',
    ]),
    async submit() {
      this.loading = true;
      if (this.state === 'init') {
        await this.authRequest(this.phoneNumbers);
      } else if (this.state === 'requested') {
        await this.authVerify(this.code);
      }
      this.loading = false;
    },
  },
  watch: {
    async phoneNumbers() {
      if (this.phoneNumbers.length === 10) {
        setTimeout(() => this.submit(), 200);
      }
    },

    code() {
      if (this.code.length === 4) this.authVerify(this.code);
    },
  },
};
</script>

<style scoped>
.auth-card {
  width: 400px;
  max-width: 100vw;
}
</style>
