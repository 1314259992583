export default [
  {
    path: 'banners',
    component: () => import('@/pages/banners/banners.vue'),
  },
  {
    alias: 'banners/addedit',
    path: 'banners/addedit/:id',
    component: () => import('@/pages/banners/addEdit.vue'),
  },
];
