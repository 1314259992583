export default [
  {
    path: 'feedback',
    component: () => import('@/pages/feedback/feedback.vue'),
  },
  {
    alias: 'feedback/addedit',
    path: 'feedback/addedit/:id',
    component: () => import('@/pages/feedback/addEdit.vue'),
  },
];
