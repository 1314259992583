export default [
  {
    path: 'categories',
    component: () => import('@/pages/category/categories.vue'),
  },
  {
    alias: 'categories/addedit',
    path: 'categories/addedit/:id',
    component: () => import('@/pages/category/addEdit.vue'),
  },
];
