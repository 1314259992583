import form from './common/form';

export const CONFIG = {
  locale: 'ru',
  debug: false,
  environment: process.env.NODE_ENV,
  platform: 'main',
  apiBasePath: '',
  authBasePath: '',
  form,
};

window.CONFIG = CONFIG;

// eslint-disable-next-line import/no-dynamic-require
require(`./${process.env.NODE_ENV}`);

if (window.ENV_API_URL) {
  CONFIG.apiBasePath = window.ENV_API_URL;
}

if (window.ENV_AUTH_URL) {
  CONFIG.authBasePath = window.ENV_AUTH_URL;
}
