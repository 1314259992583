import { isValidUrl } from '@/helpers/validators/isValidUrl';
import { positiveNumber } from '@/helpers/validators/positiveNumber';

export const $validators = {
  isValidUrl,
  positiveNumber,
  required(input) {
    return !!input;
  },
};

export default $validators;
