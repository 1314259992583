export default {
  state: {
    data: [],
    positions: [],
    editItem: {
      user_name: '',
      comment: '',
      rating: 1,
      icon_url: '',
      date: undefined,
    },
  },
  mutations: {
    updateFeedbacks(state, feedbacks) {
      state.data = feedbacks;
    },
    updateFeedbacksDropped(state, feedbacks) {
      state.data = feedbacks.map((el, index) => ({ ...el, position: ++index }));
      state.positions = state.data.map((el) => ({ id: el.id, position: el.position }));
    },
    editFeedbackItemUpdate(state, item) {
      if (item !== null) {
        state.editItem = {
          user_name: item.user_name,
          comment: item.comment,
          rating: item.rating,
          icon_url: item.icon_url,
          date: item.date,
        };
      } else {
        state.editItem = {
          user_name: '',
          comment: '',
          rating: 1,
          icon_url: '',
          date: undefined,
        };
      }
    },
    deleteFeedbackItem(state, itemId) {
      state.data = state.data.filter((el) => el.id !== itemId)
        .map((el, index) => ({ ...el, position: ++index }));
    },
    clearFeedbackPositions(state) {
      state.positions = [];
    },
  },
  actions: {
    updateFeedbacks(store, feedbacks) {
      store.commit('updateFeedbacks', feedbacks);
    },
    updateFeedbacksDropped(store, feedbacks) {
      store.commit('updateFeedbacksDropped', feedbacks);
    },
    editFeedbackItemUpdate(store, item) {
      store.commit('editFeedbackItemUpdate', item);
    },
    deleteFeedbackItem(store, itemId) {
      store.commit('deleteFeedbackItem', itemId);
    },
    clearFeedbackPositions(store) {
      store.commit('clearFeedbackPositions');
    },
  },
};
